import React, { Component } from "react";
import ProjectScreen from "../../components/ProjectsScreen";
import pp1 from "../../images/pp1.png";
import pp2 from '../../images/pp2.png';
import pp3 from '../../images/pp3.png';

export default class PantryPals extends Component {
  render() {
    return (
      <ProjectScreen
        img1={pp1}
        img2={pp2}
        img3={pp3}
        title="Developed for Ignition Hacks 2023 👨‍💻"
        description="Pantry Pals is a full-stack web application that helps users meal prep, reduce food waste, and save money. Users can input the ingredients they have in their pantry, and the app will generate recipes based on those ingredients. We used OpenAI to generate the recipes, and Google Maps to find the nearest grocery stores with the best price. I learned a lot about databases and APIs during this event, and I had a blast coding in my first hackathon! "
        techText="Tech Stack: React, Node.js, Express, MongoDB, OpenAI, Google Maps"
        projectName={"Pantry Pals"}
        github="https://github.com/prannyv/pump"
        devpost="https://devpost.com/software/pantry-pal-yzhdog"
      />
    );
  }
}
