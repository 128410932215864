import React, { Component } from "react";
import ProjectsScreen from "../../components/ProjectsScreen";
import Remi1 from '../../images/Remi1.png';
import Remi2 from '../../images/remi2.png';
import Remi3 from '../../images/remi3.png';

export default class Remi extends Component {
  render() {
    return (
      <ProjectsScreen
        img1={Remi1}
        img2={Remi2}
        img3={Remi3}
        title="1st Place UofTHacks 11 🏆"
        description="Remi (remember me) is a full-stack application that helps dementia patients remember their past using reminiscence therapy. The app prompts users with questions about their past, and generates a personalized story using Cohere API. We utilize a machine learning library to mimic the voices of their loved ones, and then read out the story to the patient using an Arduino UNO. This was a super fun event at UofTHacks 11 and we were so happy to win 1st place in the RBC Retro Revolution category and $1500!"
        techText="Tech Stack: React, ThreeJS, Python, Flask, Kintone, Cohere"
        projectName={"Remi"}
        github="https://github.com/alvina-yang/HelloRemi"
        devpost="https://devpost.com/software/remi-bo5sil"
      />
    );
  }
}
