import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

const ProjectScreen = ({ img1, img2, img3, title, description, techText, projectName, github, figma, devpost, link }) => {
  const images = [img1, img2, img3];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const fontFamilyDM = 'DM Sans, sans-serif'; // Define the font variable

  const imageContainerStyle = {
    backgroundColor: '#15505F',
    padding: '2rem',
    borderRadius: '10px',
    transition: 'transform 0.3s ease',
    transform: isHovered ? 'translateY(-5px)' : 'translateY(0)',
    border: '0.2rem solid #023047',
  };

  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }} // Column on mobile, row on larger screens
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#001926"
      px={{ xs: 2, md: 4 }} // Padding adjustments for mobile
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mb={{ xs: 4, md: 0 }} // Margin adjustment for spacing on mobile
      >
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" mb={2}>
          <Typography variant="h4" color="white" sx={{ fontFamily: fontFamilyDM }}>
            {projectName}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton onClick={handlePrevClick} style={{ color: 'white' }}>
            <ArrowBackIos />
          </IconButton>
          <Box
            style={imageContainerStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sx={{ width: { xs: '300px', md: '400px' }, height: { xs: '225px', md: '300px' } }}
          >
            <img src={images[currentImageIndex]} alt="Project" style={imageStyle} />
          </Box>
          <IconButton onClick={handleNextClick} style={{ color: 'white' }}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
      <Box ml={{ xs: 0, md: 4 }} color="white" textAlign={{ xs: 'center', md: 'left' }}>
        <Typography style={{ fontSize: '1.5rem', fontWeight: '400', padding: '1.5rem 0', fontFamily: fontFamilyDM }} gutterBottom>
          {title}
        </Typography>
        <Typography style={{ fontSize: '1.2rem', maxWidth: '100%', paddingBottom: '1rem', fontFamily: fontFamilyDM }} paragraph>
          {description}
        </Typography>
        <Typography style={{ fontSize: '1.2rem', fontFamily: fontFamilyDM }}>
          {techText}
        </Typography>
        <Box mt={4}>
          {link && (
            <Typography component="a" href={link} target="_blank" style={{ ...linkStyle, fontFamily: fontFamilyDM }}>
              Link
            </Typography>
          )}
          {github && (
            <Typography component="a" href={github} target="_blank" style={{ ...linkStyle, fontFamily: fontFamilyDM }}>
              Github
            </Typography>
          )}
          {devpost && (
            <Typography component="a" href={devpost} target="_blank" style={{ ...linkStyle, fontFamily: fontFamilyDM }}>
              Devpost
            </Typography>
          )}
          {figma && (
            <Typography component="a" href={figma} target="_blank" style={{ ...linkStyle, fontFamily: fontFamilyDM }}>
              Figma
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const linkStyle = {
  color: 'white',
  textDecoration: 'underline',
  marginRight: '1rem',
  fontSize: '1rem',
};

export default ProjectScreen;
