import React, { Component } from "react";
import ProjectsScreen from "../../components/ProjectsScreen";
import Qiwi1 from '../../images/Qiwi1.png';
import Qiwi2 from '../../images/Qiwi2.png';
import Qiwi3 from '../../images/Qiwi3.png';

export default class Qiwi extends Component {
  render() {
    return (
      <ProjectsScreen
        img1={Qiwi1}
        img2={Qiwi2}
        img3={Qiwi3}
        title="1st Place HawkHacks 🏆"
        description="Qiwi is a blockchain-based loyalty program that provide a secure and sustainable solution for businesses and customers. We created smart contracts in Solidity to manage the transactions and redemption of the Qiwi coins. These contracts are deployed on the Metis Layer 2 blockchain, which offers scalability and low transaction fees. Our web application using Next.js and React, which is connected to the blockchain via Web3.js, enabling seamingless interaction with the smart contracts. We utilized Hardhat to test and deploy the smart contracts, ensuring that our contracts were robust and secure. I learned a lot about blockchain and Web3 development during this hackathon, and me and my team were so happy to win Best DEI Hack by Fidelity, Best use of METIS, and $1000!   "
        techText="Tech Stack: Next, React, Web3, Solidity, Hardhat"
        projectName={"Qiwi"}
        github="https://github.com/JackyLiu13/Qiwi"
        devpost="https://taikai.network/hackbox/hackathons/hawkhacks/projects/clwdefsb50ebvyg019777e6bj/idea"
      />
    );
  }
}
