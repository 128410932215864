import React, { useState } from 'react';

const ProjectsCard = ({ title, link, img }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const fontFamilyDM = 'DM Sans, sans-serif'; // Define the font variable

  const cardStyle = {
    width: '20rem',
    height: '25rem',
    backgroundColor: '#15505F',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px',
    padding: '10px',
    textDecoration: 'none',
    transition: 'transform 0.3s ease', // Add transition for smooth animation
    transform: isHovered ? 'translateY(-10px)' : 'translateY(0)', // Apply transform based on hover state
    border: '0.3rem solid #023047', // Add thin black outline
  };

  const linkStyle = {
    textDecoration: 'none',
    color: 'inherit',
  };

  const imageStyle = {
    width: '80%',
    height: '100%',
    objectFit: 'cover',
    maxHeight: '17rem',
    borderRadius: '5px',
    marginBottom: '0rem',
    marginTop: '2rem',
  };

  const titleStyle = {
    color: 'white',
    fontSize: '1.5rem',
    alignSelf: 'flex-start', // Align the text to the start of the card horizontally
    marginLeft: '20px',
    marginBottom: '20px',
    fontWeight: '350',
    fontFamily: fontFamilyDM, // Apply the font variable here
  };

  return (
    <a href={link} style={linkStyle}>
      <div
        style={cardStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img style={imageStyle} src={img} alt="Hello" />
        <h3 style={titleStyle}>{title}</h3>
      </div>
    </a>
  );
};

export default ProjectsCard;
