import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Projects from './pages/Projects';
import Navbar from './components/Navbar';
import Experience from './pages/Experience';
import About from './pages/About';
import Footer from './components/Footer';

import Remi from './pages/Projects/Remi';
import Qiwi from './pages/Projects/Qiwi';
import PantryPals from './pages/Projects/PantryPals';
import IveySustainability from './pages/Projects/IveySustainability';
import MicrosoftRewards from './pages/Projects/MicrosoftRewards';
import CYM from './pages/Projects/CYM';

const App = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={
            <div>
              <div id="home">
                <Home />
              </div>
              <div id="projects">
                <Projects />
              </div>
              <div id="experience">
                <Experience />
              </div>
              <div id="about">
                <About />
              </div>
              <Footer/>
            </div>
          } />
          <Route path="/projects/Remi" element={<Remi />} />
          <Route path="/projects/Qiwi" element={<Qiwi />} />
          <Route path="/projects/pantry-pals" element={<PantryPals />} />
          <Route path="/projects/ivey-sustainability" element={<IveySustainability />} />
          <Route path="/projects/CYM" element={<CYM />} />
          <Route path="/projects/microsoft-rewards" element={<MicrosoftRewards />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
