import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';

const experienceData = [
  {
    title: 'Backend Software Developer @ PHC',
    time: 'May 2024 - Jun 2024',
    description: '',
    details: [
      'Tools: Python, JavaScript, React, Node, MongoDB',
      'Developed and implemented a scalable backend using NodeJS and designed RESTful APIs for a mobile application, improving request handling and supporting a user base growth of over 200+ active users upon launch',
      'Designed and optimized MongoDB database with over 5000 records, implementing advanced indexing and sharding strategies to reduce query execution times by 20%, ensuring smooth performance as the user base scaled',
      'Collaborated with cross-functional teams to resolve app inconsistencies, resulting in a 25% increase in downloads',
      'Created system architecture diagrams for application components reducing workload by 5 hours a week'
    ],
  },
  {
    title: 'iOS Software Developer @ Match [Startup]',
    time: 'Jan 2024 - Apr 2024',
    description: '',
    details: [
      'Tools: Python, JavaScript, React Native, Django, PostgresSQL',
      'Developed mobile MVP for Match using React Native and Python leading to $100,000 pre-seed funding',
      'Implemented various screens for iOS devices using XCode simulator reducing launch date by 2 weeks',
      'Identified and documented areas for improvement resulting in a 30% improvement in operational efficiency',
      'Configured front-end endpoints in Django for efficient data communication with PostgreSQL database'


    ],
  },
  {
    title: 'Software & Machine Learning Developer @ Western AI',
    time: 'Nov 2023 - Feb 2024',
    description: '',
    details: [
      'Tools: Python, Numpy, Pandas, TensorFlow',
      'Created a NLM using Tensorflow Keras libraries in python with over 89% accuracy in team of 5',
      'Curated dataset of 2000+ images through Kaggle and formatted dataframe using Numpy and Pandas',
      'Applied SDLC principles and Agile model for efficient project management and development process'
    ],
  },
];

const ExperienceItem = ({ title, time, description, details }) => {
  const { ref, inView } = useInView({
    threshold: 0.1, // Adjust this value to control when the element becomes visible
    triggerOnce: false, // Only trigger the animation once
  });

  const fontFamilyDM = 'DM Sans, sans-serif'; // Define the font variable

  return (
    <Box
      ref={ref}
      sx={{
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(50px)',
        transition: 'opacity 1s, transform 1s',
        backgroundColor: '#15505F',
        padding: '2rem 4rem',
        borderRadius: '1.2rem',
        marginBottom: '2rem',
        border: '0.2rem solid #023047',
        '&:hover': {
          transform: 'translateY(-10px)',
          transition: 'transform 0.3s ease',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" color="white" sx={{ marginLeft: '-1rem', fontSize: { xs: '1rem', md: '2rem' }, fontFamily: fontFamilyDM }}>
          {title}
        </Typography>
        <Typography variant="body2" color="white" sx={{ fontSize: { xs: '1rem', md: '1rem' }, fontFamily: fontFamilyDM }}>
          {time}
        </Typography>
      </Box>
      {description && (
        <Typography variant="body2" color="white" style={{ marginTop: '10px', fontFamily: fontFamilyDM }}>
          {description}
        </Typography>
      )}
      <ul style={{ color: 'white', marginTop: '10px', fontFamily: fontFamilyDM }}>
        {details.map((detail, index) => (
          <li style={{ padding: '0.15rem' }} key={index}>{detail}</li>
        ))}
      </ul>
    </Box>
  );
};

const Experience = () => {
  const fontFamilyDM = 'DM Sans, sans-serif'; // Define the font variable

  return (
    <Container>
      <Box mt={10}>
        <div style={{ color: 'white', fontSize: '2rem' }}>
          <Typography sx={{ fontSize: { xs: '3rem', sm: '3rem', md: '4.5rem' }, padding: '0 0 4rem 0', fontFamily: fontFamilyDM }}>
            Experience 🚀
          </Typography>
        </div>
        <div>
          {experienceData.map((item, index) => (
            <ExperienceItem key={index} {...item} />
          ))}
        </div>
      </Box>
    </Container>
  );
};

export default Experience;
