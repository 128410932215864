import React, { useState, useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';
import Typer from '../components/Typer';

const Home = () => {
  const [visible, setVisible] = useState(false);

  // Define the font variable
  const fontFamilyDM = 'DM Sans, sans-serif';

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div>
      <style>
        {`
          @keyframes wave {
            0% { transform: rotate(0deg); }
            15% { transform: rotate(14deg); }
            30% { transform: rotate(-8deg); }
            40% { transform: rotate(14deg); }
            50% { transform: rotate(-4deg); }
            60% { transform: rotate(10deg); }
            100% { transform: rotate(0deg); }
          }

          .wave:hover {
            animation: wave 1s ease-in-out infinite;
          }
        `}
      </style>
      <Container>
        <Box mt={10}>
          <div
            style={{
              fontFamily: 'Inter',
              color: 'white',
              padding: '10rem 0 0 0rem',
              fontSize: '2rem',
              opacity: visible ? 1 : 0,
              transform: visible ? 'translateY(0)' : 'translateY(20px)',
              transition: 'opacity 1.5s ease-in-out, transform 1.5s ease-in-out',
            }}
          >
            <Typography
              sx={{
                padding: '0 0 1.5rem 0',
                fontWeight: '400',
                fontSize: { xs: '2.5rem', sm: '4rem', md: '5rem' },
                fontFamily: fontFamilyDM,
              }}
            >
              Hi! <span style={{ display: 'inline-block', cursor: 'pointer' }} className="wave">👋</span> I’m Chandler
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                maxWidth: '80rem',
                lineHeight: { xs: '3rem', sm: '4rem', md: '4.5rem' },
                fontSize: { xs: '2rem', sm: '3rem', md: '4.5rem' },
                margin: '0 0 0 0',
                fontFamily: fontFamilyDM,
              }}
            >
              An aspiring {' '}
              <Typer />
            </Typography>
            <Typography
              sx={{
                padding: '5rem 0 2rem 0',
                fontWeight: '300',
                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.7rem' },
                fontFamily: fontFamilyDM,
              }}
            >
              I am currently studying Computer Science at <span style={{ color: '#DF85FF' }}>Western</span> University
            </Typography>
            <Typography
              sx={{
                padding: '0 0 4rem 0',
                fontWeight: '300',
                fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.7rem' },
                fontFamily: fontFamilyDM,
              }}
            >
              Here is some of my work!
            </Typography>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default Home;
