import React from "react";
import { Container, Box, Typography, Button } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import chandler from '../images/chandler.png';
import chandler2 from '../images/chandler2.jpg';

const About = () => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Animation triggers only once
    threshold: 0.1,    // Percentage of the component that needs to be visible to trigger the animation
  });

  const fadeInStyle = {
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    transition: 'opacity 1.5s ease, transform 1.5s ease',
  };

  // Define the font variable
  const fontFamilyDM = 'DM Sans, sans-serif';

  return (
    <div ref={ref}>
      <Container>
        <Box mt={10}>
          <div style={{ color: 'white', fontSize: '2rem' }}>
            <Typography sx={{ fontSize: { xs: '3.5rem', sm: '3rem', md: '4.5rem' }, padding: '0 0 4rem 0', fontFamily: fontFamilyDM }}>
              About 🤔
            </Typography>
          </div>
          <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            flexDirection={{ xs: 'column', md: 'row' }} 
            style={fadeInStyle}  // Apply fade-in animation to the container
          >
            <Box mb={{ xs: 4, sm: 0 }} style={fadeInStyle}> {/* Add fade-in to the image */}
              <img
                src={chandler2}
                alt="Placeholder"
                style={{
                  borderRadius: '10px',
                  marginRight: '2rem',
                  width: '400px',
                  height: '500px',
                  border: '2px solid white',
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Box color="white" maxWidth="600px" paddingLeft={{ sm: '4rem' }} style={fadeInStyle}> {/* Add fade-in to the text */}
              <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.4rem' }, fontWeight: '300', fontFamily: fontFamilyDM }}>
                Hey there! I'm Chandler, a third-year Computer Science student at Western University!
              </Typography>
              <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.4rem' }, fontWeight: '300', marginTop: '1.5rem', fontFamily: fontFamilyDM }}>
                I'm interested in making the world a better place through technology. I'm passionate about health tech, machine learning, and full-stack development. 
              </Typography>
              <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.4rem' }, fontWeight: '300', marginTop: '1.5rem', fontFamily: fontFamilyDM }}>
                Outside of school, I enjoy playing volleyball, going to the gym, and crocheting new projects. I'm also a huge fan of international cuisine, and it is my dream to travel the world and try all the different foods!
              </Typography>
              <Typography sx={{ fontSize: { xs: '1.2rem', sm: '1.4rem' }, fontWeight: '300', marginTop: '1.5rem', fontFamily: fontFamilyDM }}>
                That being said, I'm currently looking for Summer 2025 internship opportunities! If you think I'd be a good fit for your team, feel free to reach out to me :) 
              </Typography>
            </Box>
          </Box>
          <Box mt={4} display="flex" justifyContent="center" width="100%" style={fadeInStyle}> {/* Add fade-in to the button */}
            <Button 
              variant="contained" 
              color="primary" 
              href="mailto:ChandlerX101@gmail.com"
              sx={{ 
                fontSize: '1.2rem', 
                textTransform:'none', 
                padding: '1rem 2rem', 
                marginTop:'2rem', 
                backgroundColor:'#15505F', 
                borderRadius:'1rem',
                fontFamily: fontFamilyDM,
                '&:hover': {
                  backgroundColor: '#1c6c80', // Change this to your desired hover color
                },
              }}
            >
              Contact Me
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default About;
