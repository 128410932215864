import { TypeAnimation } from 'react-type-animation';
 
const Typer = () => {
  return (
    <TypeAnimation
      sequence={[
        'Software Developer', // Types 'Software Developer'
        5000, // Waits 1s
        ' '
      ]}
      wrapper="span"
      cursor={true}
      repeat={Infinity}
      style={{padding:'0 0 0 0',display: 'inline-block' }}
    />
  );
};

export default Typer;
