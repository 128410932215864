import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';

const fontFamilyDM = 'DM Sans, sans-serif'; // Define the font variable

const navbarStyle = {
  position: 'fixed',
  top: '30px', // Adjust the top position as needed
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'rgba(2, 48, 71, 0.8)', // Background color with opacity
  padding: '10px 20px',
  borderRadius: '10px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 1000, // Ensure the navbar is on top
  backdropFilter: 'blur(10px)', // Apply blur filter
  WebkitBackdropFilter: 'blur(10px)', // Apply blur filter for Safari
};

const navbarContentStyle = {
  display: 'flex',
  justifyContent: 'space-around',
};

const navLinkStyle = {
  color: 'white',
  textDecoration: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  cursor: 'pointer',
  fontFamily: fontFamilyDM, // Apply the font variable here
};

const navLinkHoverStyle = {
  backgroundColor: '#2A4A60', // Hover effect
  borderRadius: '50px',
};

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (to, duration) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scroll.scrollTo(document.getElementById(to).offsetTop, {
          duration: duration,
          smooth: true,
        });
      }, 0); // Delay to ensure the navigation has completed
    } else {
      scroll.scrollTo(document.getElementById(to).offsetTop, {
        duration: duration,
        smooth: true,
      });
    }
  };

  return (
    <div style={navbarStyle}>
      <div style={navbarContentStyle}>
        <span
          onClick={() => handleNavigation('home', 500)} // Adjust duration as needed
          style={navLinkStyle}
          onMouseEnter={e => e.target.style.backgroundColor = navLinkHoverStyle.backgroundColor}
          onMouseLeave={e => e.target.style.backgroundColor = ''}
        >
          Home
        </span>
        <span
          onClick={() => handleNavigation('projects', 500)} // Adjust duration as needed
          style={navLinkStyle}
          onMouseEnter={e => e.target.style.backgroundColor = navLinkHoverStyle.backgroundColor}
          onMouseLeave={e => e.target.style.backgroundColor = ''}
        >
          Projects
        </span>
        <span
          onClick={() => handleNavigation('experience', 500)} // Adjust duration as needed
          style={navLinkStyle}
          onMouseEnter={e => e.target.style.backgroundColor = navLinkHoverStyle.backgroundColor}
          onMouseLeave={e => e.target.style.backgroundColor = ''}
        >
          Experience
        </span>
        <span
          onClick={() => handleNavigation('about', 500)} // Adjust duration as needed
          style={navLinkStyle}
          onMouseEnter={e => e.target.style.backgroundColor = navLinkHoverStyle.backgroundColor}
          onMouseLeave={e => e.target.style.backgroundColor = ''}
        >
          About
        </span>
      </div>
    </div>
  );
};

export default Navbar;
