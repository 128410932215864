import React from 'react';
import { Box, Container, Typography, Link as MuiLink } from '@mui/material';
import ChandlerResume from '../images/ChandlerXieResume.pdf'; 

const Footer = () => {
  const fontFamilyDM = 'DM Sans, sans-serif'; // Define the font variable

  return (
    <Box className="footer-background" py={2} mt={10}>
      <Container maxWidth="lg"> 
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '2rem' }}>
          <Typography 
            variant="body1" 
            color="#8ecae6" 
            sx={{
              fontSize: { xs: '0rem', md: '1.5rem' }, // Hide text on small screens
              display: { xs: 'none', md: 'block' },   // Show text only on medium and larger screens
              fontFamily: fontFamilyDM, // Apply the font variable here
            }}
          >
            Chandler Xie
          </Typography>
          <Box>
            <MuiLink 
              href="https://github.com/chandlerkx" 
              target="_blank" 
              rel="noopener" 
              color="inherit" 
              mx={2} 
              sx={{ color: "#8ecae6", textTransform: 'none', fontFamily: fontFamilyDM }} // Apply the font variable here
            >
              Github
            </MuiLink>
            <MuiLink 
              href="https://www.linkedin.com/in/chandlerxie/" 
              target="_blank" 
              rel="noopener" 
              color="inherit" 
              mx={2} 
              sx={{ color: "#8ecae6", fontFamily: fontFamilyDM }} // Apply the font variable here
            >
              LinkedIn
            </MuiLink>
            <MuiLink 
              href="mailto:ChandlerX101@gmail.com" 
              target="_blank" 
              rel="noopener" 
              color="inherit" 
              mx={2} 
              sx={{ color: "#8ecae6", fontFamily: fontFamilyDM }} // Apply the font variable here
            >
              Email
            </MuiLink>
            <MuiLink 
              href={ChandlerResume} 
              target="_blank" 
              rel="noopener" 
              color="inherit" 
              mx={2} 
              sx={{ color: "#8ecae6", fontFamily: fontFamilyDM }} // Apply the font variable here
            >
              Resume
            </MuiLink>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
