import React, { Component } from "react";
import ProjectScreen from "../../components/ProjectsScreen";
import CYM1 from "../../images/CYM1.png";
import CYM2 from '../../images/CYM2.png';
import CYM3 from '../../images/CYM3.png';

export default class CYM extends Component {
  render() {
    return (
      <ProjectScreen
        img1={CYM1}
        img2={CYM2}
        img3={CYM3}
        title="Freelance Project"
        description="Connecting Youth in Med is a organization that educates students on the healthcare industry. I worked with them to create a web application that educates students about their mission, their past and current events, and how they can get involved. The website was designed to be user-friendly and easy to navigate, with a clean and modern aesthetic."
        techText="Tech Stack: React, Node.js, Express, MongoDB"
        projectName={"Connecting Youth in Med"}
        link="about:blank"
        github="https://github.com/chandlerkx/CYMWebsite"
        figma="https://www.figma.com/design/iaRepWMUy6XgKh5SHKa97V/connecting-youth-in-med?node-id=0-1&t=07GeLYZHV6XJ4ald-0"
      />
    );
  }
}
