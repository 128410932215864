import React, { Component } from "react";
import ProjectScreen from "../../components/ProjectsScreen";
import IS1 from "../../images/IS1.png";
import IS2 from '../../images/IS2.png';
import IS3 from '../../images/IS3.png';

export default class IveySustainability extends Component {
  render() {
    return (
      <ProjectScreen
        img1={IS1}
        img2={IS2}
        img3={IS3}
        title="Freelance Project for WDS"
        description="The Ivey Sustainability club is a student-led organization that aims to promote sustainability and environmental awareness at the Ivey Business School. I worked with the club to create a website that would serve as a hub for all their events, resources, and initiatives. The website was designed to be user-friendly and easy to navigate, with a clean and modern aesthetic. This was my first time working with React, and I learned a lot about front-end development and design in the process!"
        techText="Tech Stack: React, Node.js, Tailwind.css"
        projectName={"Ivey Sustainability"}
        github="https://github.com/chandlerkx/ivey-sustainability"
        figma="https://www.figma.com/design/DHzI4BfPD16dqoY40Z28yz/Ivey-MSc-Sustainability?node-id=0-1&t=snIoCZxVckA9fS4w-0"
        link="about:blank"
      />
    );
  }
}
