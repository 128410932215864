import { Box, Container, Typography } from "@mui/material";
import React from "react";
import ProjectsCard from '../components/ProjectsCard';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import Remi from '../images/remi.png';
import Qiwi from '../images/Qiwi.png';
import IveySus from '../images/IveySus.png';
import PantryPals from '../images/pantrypals.png';
import CYM from '../images/CYM.png';

const ProjectLink = ({ to, title, img }) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: false,
  });

  const fadeInStyle = {
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    transition: 'opacity 0.6s ease, transform 0.6s ease',
  };

  return (
    <Link to={to} style={{ ...linkStyle, ...fadeInStyle }} ref={ref}>
      <ProjectsCard title={title} img={img} />
    </Link>
  );
};

const Projects = () => {
  // Define the font variable
  const fontFamilyDM = 'DM Sans, sans-serif';

  return (
    <div style={{ padding: '3rem 0 0 0rem' }}>
      <Container>
        <Box mt={10}>
          <div style={{ color: 'white', fontSize: '2rem' }}>
            <Typography 
              sx={{ 
                fontSize: { xs: '3.5rem', sm: '3rem', md: '4.5rem' }, 
                padding: '0 0 4rem 0',
                fontFamily: fontFamilyDM, // Apply the font variable here
              }}
            >
              Projects 💻
            </Typography>
          </div>
          <div style={cardsContainerStyle}>
            <ProjectLink to="/projects/remi" title="Remi" img={Remi} />
            <ProjectLink to="/projects/qiwi" title="Qiwi" img={Qiwi} />
            <ProjectLink to="/projects/pantry-pals" title="Pantry Pals" img={PantryPals} />
            <ProjectLink to="/projects/ivey-sustainability" title="Ivey Sustainability" img={IveySus} />
            <ProjectLink to="/projects/cym" title="Connecting Youth in Med" img={CYM}/>
          </div>
        </Box>
      </Container>
    </div>
  );
};

const cardsContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '1rem', // Adjust the value to control the space between the cards
};

const linkStyle = {
  textDecoration: 'none',
};

export default Projects;
