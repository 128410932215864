import React, { Component } from "react";
import ProjectScreen from "../../components/ProjectsScreen";

export default class MicrosoftRewards extends Component {
  render() {
    return (
      <ProjectScreen
        img1="https://via.placeholder.com/300x301"
        img2="https://via.placeholder.com/300x302"
        img3="https://via.placeholder.com/300x303"
        title="Solving modern problems with modern solutions"
        description="Microsoft Rewards is a project that I worked on with a team of developers. It is a web application that allows users to create and share recipes. Users can also search for recipes based on ingredients they have in their pantry."
        techText="Tech Stack: React, Node.js, Express, MongoDB"
        projectName={"Microsoft Rewards"}
        github="github.com"
        devpost="devpost.com"
      />
    );
  }
}
